import Dashboard from "./components/Dashboard";
import * as React from "react";
import { generatePath, ParamParseKey } from "react-router-dom";
import { Playground } from "./components/Playground";
import _000Login from "./components/_000Login";
import _013Registrierung02 from "./components/_013Registrierung02";
import _013Registrierung03 from "./components/_013Registrierung03";
import AuthenticatedNewRealtyForm from "./components/AuthenticatedNewRealtyForm";
import RealtySnapshotDetail from "./components/RealtySnapshotDetail";
import _022BerichtSuccessRegistriert from "./components/_022BerichtSuccessRegistriert";
import GuestNewRealtyAddress from "./components/GuestNewRealtyAddress";
import AuthenticatedNewRealtyAddress from "./components/AuthenticatedNewRealtyAddress";
import GuestNewRealtyForm from "./components/GuestNewRealtyForm";
import GuestPhoneVerification from "./components/GuestPhoneVerification";
import GuestReportCreated from "./components/GuestReportCreated";
import Benutzereinstellungen from "./components/Benutzereinstellungen";
import _001PasswordReset01 from "./components/_001PasswordReset01";
import _002PasswordReset02 from "./components/_002PasswordReset02";
import EsgQuestionnaire from "./components/EsgQuestionnaire";

// All routes in the application are defined here, together with a name to reference in the code (key of object),
// the path and the component to render. If the component has params (e.g. /:id), they are specified in the
// "params" array and currently purely used to check the presence of the required parameter when navigating
// to the route make sure the route is valid (and provide type helpers).
// The "as const" modifier is important here to make the keys available in the type definition. Removing it
// would cause typescript to understand it as dynamic value which could change during runtime and thus is not available
// in the type definition.
const ROUTE_MAPPING = {
  root: { path: "/", component: Dashboard },
  login: { path: "/login", component: _000Login },
  requestPasswordReset: { path: "/reset-password", component: _001PasswordReset01 },
  passwordReset: { path: "/reset-password/:token", component: _002PasswordReset02, params: ["token"] as const },
  signupVerification: {
    path: "/signup/verify",
    component: _013Registrierung02,
  },
  signupAddress: {
    path: "/signup/address",
    component: _013Registrierung03,
  },

  // TODO: Remove this alias after updating the links in plasmic
  realtyNew: { path: "/realty/new", component: AuthenticatedNewRealtyAddress },
  authenticatedRealtyNewAddress: { path: "/realty/new/address", component: AuthenticatedNewRealtyAddress },
  authenticatedRealtyNewForm: { path: "/realty/new/form", component: AuthenticatedNewRealtyForm },
  realtySnapshotDetail: {
    path: "/realty/:realtyId/snapshot/:realtySnapshotId",
    component: RealtySnapshotDetail,
    params: ["realtyId", "realtySnapshotId"] as const,
  },
  userSettings: { path: "/settings", component: Benutzereinstellungen },

  guestRealtyNewAddress: { path: "/guest/realty/new/address", component: GuestNewRealtyAddress },
  guestRealtyNewForm: { path: "/guest/realty/new/form", component: GuestNewRealtyForm },
  guestVerification: { path: "/guest/verification", component: GuestPhoneVerification },
  guestReportCreated: { path: "/guest/report", component: GuestReportCreated },

  esgQuestionnaire: { path: "/esg", component: EsgQuestionnaire },

  reportCreated: { path: "/report/:reportId", component: _022BerichtSuccessRegistriert, params: ["reportId"] as const },
  playground: { path: "/playground", component: Playground },
} as const;

export type NamedRoute = keyof typeof ROUTE_MAPPING;
type ParamKeys<T extends NamedRoute> = ParamParseKey<(typeof ROUTE_MAPPING)[T]["path"]>;

interface Mapping {
  [key: string]: {
    path: string;
    component: React.ComponentType;
    params?: readonly string[];
  };
}

export const ApplicationRoutes = {
  getPath<T extends NamedRoute>(name: T, params?: Record<ParamKeys<T>, string>) {
    return generatePath<(typeof ROUTE_MAPPING)[T]["path"]>(ROUTE_MAPPING[name].path, params);
  },

  map<U>(func: (obj: { path: string; component: React.ComponentType }) => U): U[] {
    return Object.keys(ROUTE_MAPPING).map<U>((key) => func((ROUTE_MAPPING as Mapping)[key]));
  },
};
