import { apiEndpoint, EndpointConfig, Request } from "./apiEndpoint";
import React from "react";
import AuthToken from "./AuthToken";
import AuthenticationError from "./AuthenticationError";
import { Navigate } from "react-router-dom";
import { attempt, isError, isNil } from "lodash";
import {
  BannerEntry,
  ListRealtiesResponse,
  RealtyResponse,
  RealtySnapshotResponse,
  Remms4allForm,
  ReportResponse,
  UserUpdateRequest,
  ReportId,
  RealtyCreationRequest,
  PasswordUpdateRequest,
  RegisteredUserResponse,
  SnapshotId,
  FeatureResponse,
  ImportResponse,
  ImportRequest,
  PortfolioForm,
  PortfolioVersion,
  PortfolioSnapshotResponse,
  ShowLatestPortfolioResponse,
} from "./types";

export class AuthenticatedApiService {
  constructor(public authToken: AuthToken) {
    if (authToken.isExpired()) {
      throw new AuthenticationError("Auth token expired");
    }
  }

  private authenticatedApiEndpoint<Req extends Request, Res>(options: EndpointConfig) {
    return apiEndpoint<Req, Res>({
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${this.authToken}`,
      },
    });
  }

  get session() {
    return {
      signOut: this.authenticatedApiEndpoint<undefined, undefined>({
        path: "/users/sign_out",
        method: "DELETE",
      }),
    };
  }

  get currentUser() {
    return {
      update: this.authenticatedApiEndpoint<UserUpdateRequest, undefined>({
        path: "/authenticated/current_registered_user",
        method: "PATCH",
      }),

      get: this.authenticatedApiEndpoint<undefined, RegisteredUserResponse>({
        path: "/authenticated/current_registered_user",
        method: "GET",
      }),

      delete: this.authenticatedApiEndpoint<undefined, undefined>({
        path: "/authenticated/current_registered_user",
        method: "DELETE",
      }),
    };
  }

  get features() {
    return {
      get: this.authenticatedApiEndpoint<undefined, FeatureResponse>({
        path: "/authenticated/features",
        method: "GET",
      }),
    };
  }

  get registrations() {
    return {
      update: this.authenticatedApiEndpoint<PasswordUpdateRequest, undefined>({
        path: "/users",
        method: "PATCH",
      }),
    };
  }

  get dashboard() {
    return {
      listRealties: this.authenticatedApiEndpoint<undefined, ListRealtiesResponse>({
        path: "/authenticated/dashboard/list_realties",
        method: "GET",
      }),
      realty: (id: string) => {
        return {
          listSnapshots: this.authenticatedApiEndpoint<undefined, RealtySnapshotResponse[]>({
            path: `/authenticated/dashboard/realty/${id}/snapshots`,
            method: "GET",
          }),
        };
      },
    };
  }

  get portfolioVersions() {
    return {
      post: this.authenticatedApiEndpoint<PortfolioForm, undefined>({
        path: "/authenticated/portfolio_versions",
        method: "POST",
      }),
      get: this.authenticatedApiEndpoint<undefined, ShowLatestPortfolioResponse>({
        path: "/authenticated/portfolio_versions/latest",
        method: "GET",
      }),
      index: this.authenticatedApiEndpoint<undefined, PortfolioVersion[]>({
        path: "/authenticated/portfolio_versions",
        method: "GET",
      }),
      portfolioVersion: (id: string) => {
        return {
          listSnapshots: this.authenticatedApiEndpoint<undefined, PortfolioSnapshotResponse[]>({
            path: `/authenticated/portfolio_versions/${id}/snapshots`,
            method: "GET",
          }),
          delete: this.authenticatedApiEndpoint<undefined, undefined>({
            path: `/authenticated/portfolio_versions/${id}`,
            method: "DELETE",
          }),
        };
      },
    };
  }

  get import() {
    return {
      get: this.authenticatedApiEndpoint<undefined, ImportResponse>({
        path: "/authenticated/import",
        method: "GET",
      }),
      post: this.authenticatedApiEndpoint<ImportRequest, undefined>({
        path: "/authenticated/import",
        method: "POST",
      }),
    };
  }

  get realties() {
    return {
      create_with_report: this.authenticatedApiEndpoint<RealtyCreationRequest, ReportId>({
        path: `/authenticated/realties/create_with_report`,
        method: "POST",
      }),
      create: this.authenticatedApiEndpoint<RealtyCreationRequest, SnapshotId>({
        path: `/authenticated/realties`,
        method: "POST",
      }),
    };
  }

  report(id: string) {
    return {
      get: this.authenticatedApiEndpoint<undefined, ReportResponse>({
        path: `/authenticated/realty_reports/${id}`,
        method: "GET",
      }),
    };
  }

  realty(id: string) {
    return {
      get: this.authenticatedApiEndpoint<undefined, RealtyResponse>({
        path: `/authenticated/realties/${id}`,
        method: "GET",
      }),
      delete: this.authenticatedApiEndpoint<undefined, undefined>({
        path: `/authenticated/realties/${id}`,
        method: "DELETE",
      }),
      toggle_archive: this.authenticatedApiEndpoint<undefined, undefined>({
        path: `/authenticated/realties/${id}/toggle_archived`,
        method: "POST",
      }),
      snapshots: {
        create_with_report: this.authenticatedApiEndpoint<
          { realty_snapshot: { remms4all_form: Remms4allForm } },
          ReportId
        >({
          path: `/authenticated/realties/${id}/snapshots/create_with_report`,
          method: "POST",
        }),
        create: this.authenticatedApiEndpoint<{ realty_snapshot: { remms4all_form: Remms4allForm } }, SnapshotId>({
          path: `/authenticated/realties/${id}/snapshots`,
          method: "POST",
        }),
      },
    };
  }

  snapshot(snapshotId: string | number) {
    return {
      activate: this.authenticatedApiEndpoint<undefined, undefined>({
        path: `/authenticated/realty_snapshots/${snapshotId}/activate`,
        method: "PATCH",
      }),
      get: this.authenticatedApiEndpoint<undefined, RealtySnapshotResponse>({
        path: `/authenticated/realty_snapshots/${snapshotId}`,
        method: "GET",
      }),
      delete: this.authenticatedApiEndpoint<undefined, undefined>({
        path: `/authenticated/realty_snapshots/${snapshotId}`,
        method: "DELETE",
      }),
    };
  }

  get banner() {
    return {
      get: this.authenticatedApiEndpoint<undefined, BannerEntry[]>({
        path: `/authenticated/banner`,
        method: "GET",
      }),
    };
  }
}

export type WithAuthenticatedApiServiceProps = {
  authenticatedApiService: AuthenticatedApiService;
};

const defaultConfig = {
  redirectOnFailedAuthentication: true,
  redirectOnIncompleteSignup: true,
  failedAuthFallbackComponent: <Navigate to={"/login"} replace={true} />,
  incompleteSignupFallbackComponent: <Navigate to={"/signup/address"} replace={true} />,
};

export const withAuthenticatedApiService = <P extends WithAuthenticatedApiServiceProps>(
  Component: React.ComponentType<P>,
  config?: Partial<typeof defaultConfig>,
) => {
  const { redirectOnFailedAuthentication, redirectOnIncompleteSignup, ...fallbacks } = {
    ...defaultConfig,
    ...(config ?? {}),
  };

  const WithAuthenticatedApiService = React.forwardRef<any, Omit<P, keyof WithAuthenticatedApiServiceProps>>(
    (props, ref) => {
      const failedAuthFallbackComponent = redirectOnFailedAuthentication && fallbacks.failedAuthFallbackComponent;
      const incompleteSignupFallbackComponent =
        redirectOnIncompleteSignup && fallbacks.incompleteSignupFallbackComponent;
      const authToken = AuthToken.get();

      const authenticatedApiService = React.useMemo<AuthenticatedApiService | null>(() => {
        if (!authToken) return null;
        const service = attempt((authToken) => new AuthenticatedApiService(authToken), authToken);
        if (isNil(service) || isError(service)) return null;
        return service as AuthenticatedApiService;
      }, [authToken]);

      const renderComponent = () => (
        <Component
          {...({
            ...props,
            authenticatedApiService,
            ref,
          } as unknown as P)}
        />
      );

      if (authenticatedApiService) {
        if (authenticatedApiService.authToken.userCompletedSignup()) {
          return renderComponent();
        } else {
          return incompleteSignupFallbackComponent || renderComponent();
        }
      } else {
        return failedAuthFallbackComponent || renderComponent();
      }
    },
  );

  WithAuthenticatedApiService.displayName = `WithAuthenticatedApiService(${Component.displayName || Component.name})`;

  return WithAuthenticatedApiService;
};
